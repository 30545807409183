export function getIDfromRoute() {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var userId = url.searchParams.get("id");
    return userId;
}

export async function removeToken() {
    var userid = await getIDfromRoute();
    var tokenList = JSON.parse(localStorage.getItem('idcToken')) || [];
    var updatedList = [];

    tokenList.forEach(element => {
        if (element.id != userid)
            updatedList.push(element);
    });

    localStorage.setItem('idcToken', JSON.stringify(updatedList));
    localStorage.removeItem('currencyToken');
    localStorage.removeItem('userID');


}

export function setToken(userInfo) {
    var tokenList = JSON.parse(localStorage.getItem('idcToken')) || [];
    var updatedList = [];

    const found = tokenList.some((el) => el.id == userInfo.id);
    if (found)
        tokenList.forEach(element => {
            if (element.id == userInfo.id)
                updatedList.push(userInfo);
            else
                updatedList.push(element);
        });
    else {
        updatedList = tokenList;
        updatedList.push(userInfo);
    }

    localStorage.setItem('idcToken', JSON.stringify(updatedList));
    localStorage.setItem('currencyToken', userInfo.token);
    localStorage.setItem('userID', userInfo.id);

    return userInfo.id;

}

export async function getToken() {
    // localStorage.removeItem('currencyToken');
    // localStorage.removeItem('userID');
    var userid = await getIDfromRoute();
    var tokenList = JSON.parse(localStorage.getItem('idcToken')) || [];
    var userInfo = { id: '', token: '' };
    if (tokenList.length > 0) {
        const found = tokenList.some((el) => el.id == userid);
        if (found) {
            userInfo = tokenList.find((el) => el.id == userid);
            setToken(userInfo);
        }
        // else { removeToken(); }

    } else {
        removeToken();
    }
    return userInfo.token;

}