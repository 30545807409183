import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import checkAuth from "./system/auth/authenticate.js";
import checkWithdraw from "./system/auth/canWithdraw.js";
import { getToken } from "./system/auth/tokenHandling.js";
// import { getIDfromRoute, } from "./system/auth/tokenHandling.js";
Vue.use(Router);

// create new router

const routes = [
  {
    path: "/web/",
    component: () => import("./views"),
    redirect: "/web/dashboard",
    children: [
      {
        path: "/web/dashboard",
        beforeEnter: checkAuth,
        component: () => import("./views/home/dashboard"),
      },
      {
        path: "/web/announcement",
        beforeEnter: checkAuth,
        component: () => import("./views/home/announcement"),
      },
      {
        path: "/web/wallet",
        meta: { requiresAuth: true },
        beforeEnter: checkAuth,
        component: () => import("./views/wallet/record/record_page"),
      },

      {
        path: "/web/trade",
        meta: { requiresAuth: true },
        beforeEnter: checkAuth,
        component: () => import("./views/subscription/trade/trade"),
      },
      {
        path: "/web/ib_tree",
        meta: { requiresAuth: true },
        beforeEnter: checkAuth,
        component: () => import("./views/team/memberTree"),
      },
      {
        path: "/web/manage",
        meta: { requiresAuth: true },
        beforeEnter: checkAuth,
        component: () => import("./views/manage/manage"),
      },
      {
        path: "/web/commission",
        meta: { requiresAuth: true },
        beforeEnter: checkAuth,
        component: () => import("./views/manage/profile/commission/index.vue"),
      },
    ],
  },
  {
    path: "/web/subscription",
    meta: { requiresAuth: true },
    beforeEnter: checkAuth,
    component: () => import("./views/subscription"),
    redirect: "/web/subscription/list",
    children: [
      {
        path: "list",
        meta: { requiresAuth: true },
        beforeEnter: checkAuth,
        component: () => import("./views/subscription/forms/list"),
      },
      {
        path: "form",
        meta: { requiresAuth: true },
        beforeEnter: checkAuth,
        component: () => import("./views/subscription/forms"),
      },
      {
        path: "withdrawal",
        meta: { requiresAuth: true },
        beforeEnter: checkAuth,
        name: "withdrawal",
        props: true,
        component: () => import("./views/subscription/withdrawal"),
      },
    ],
  },
  {
    path: "/web/invite_link",
    meta: { requiresAuth: true },
    beforeEnter: checkAuth,
    component: () => import("./views/manage/invitePage"),
  },
  {
    path: "/web/transaction",
    meta: { requiresAuth: true },
    component: () => import("./views/manage/transaction"),
    beforeEnter: checkAuth,
    children: [
      {
        path: "transaction",
        beforeEnter: checkAuth,
        component: () =>
          import("./views/manage/transaction/transaction/transaction"),
      },
      // {
      //   path: "withdraw",
      //   beforeEnter: checkAuth,
      //   component: () => import("./views/manage/transaction/withdraw/withdraw"),
      // },
      {
        path: "deposit",
        beforeEnter: checkAuth,
        component: () =>
          import("./views/manage/transaction/transaction/deposit"),
      },
      //   path: "deposit",
      //   beforeEnter: checkAuth,
      //   component: () => import("./views/manage/transaction/deposit/depositBox"),
      // },
      // {
      //   path: "setCoin",
      //   beforeEnter: checkAuth,
      //   component: () => import("./views/manage/transaction/withdraw/setCoin"),
      // },
      // {
      //   path: "setBank",
      //   beforeEnter: checkAuth,
      //   component: () => import("./views/manage/transaction/withdraw/setBank"),
      // },
    ],
  },
  // {
  //   path: "/web/record",
  //   beforeEnter: checkAuth,
  //   component: () => import("./views/manage/record/record_page"),
  // },
  {
    path: "/web/profile",
    beforeEnter: checkAuth,
    component: () => import("./views/manage/profile"),
  },
  {
    path: "/web/kyc",
    beforeEnter: checkAuth,
    component: () => import("./views/manage/profile/kyc"),
  },
  {
    path: "/web/withdraw_info",
    meta: { requiresAuth: true },
    component: () => import("./views/manage/transaction/withdraw"),
    beforeEnter: checkAuth,
    children: [
      {
        path: "withdraw",
        meta: { requiresAuth: true },
        beforeEnter: checkWithdraw,
        component: () => import("./views/manage/transaction/withdraw/withdraw"),
      },
      {
        path: "setCoin",
        meta: { requiresAuth: true },
        beforeEnter: checkAuth,
        component: () => import("./views/manage/transaction/withdraw/setCoin"),
      },
      {
        path: "setBank",
        meta: { requiresAuth: true },
        beforeEnter: checkAuth,
        component: () => import("./views/manage/transaction/withdraw/setBank"),
      },
    ],
  },
  {
    path: "/web/security",
    meta: { requiresAuth: true },
    component: () => import("./views/manage/security"),
    beforeEnter: checkAuth,
    children: [
      {
        path: "change-password",
        beforeEnter: checkAuth,
        meta: { requiresAuth: true },
        component: () => import("./views/manage/security/change-password"),
      },

      {
        path: "change-sec-password",
        beforeEnter: checkAuth,
        meta: { requiresAuth: true },
        component: () => import("./views/manage/security/change-sec-password"),
      },

      {
        path: "forget-sec-password",
        beforeEnter: checkAuth,
        meta: { requiresAuth: true },
        component: () => import("./views/manage/security/forget-sec-password"),
      },
    ],
  },

  {
    path: "/web/rank-details",
    meta: { requiresAuth: true },
    component: () => import("./views/wallet/record/rank_details"),
  },

  {
    path: "/web/set-sec-password",
    meta: { requiresAuth: true },
    component: () => import("./views/manage/security/set-sec-password"),
  },

  {
    path: "/web/forget-password",
    component: () => import("./views/manage/security/forget-password"),
  },

  {
    path: "/web/sessions",
    component: () => import("./views/sessions"),
    children: [
      {
        path: "login",
        component: () => import("./views/sessions/signIn"),
      },
      // {
      //   path: "aboutUs",
      //   component: () => import("./views/sessions/aboutUs"),
      // },
      // {
      //   path: "tnc",
      //   component: () => import("./views/sessions/tnc"),
      // },
    ],
  },
  {
    path: "/instruction_letter",
    component: () => import("./views/sessions/instruction_letter"),
  },
  {
    path: "/privacy_policy",
    component: () => import("./views/sessions/privacy_policy"),
  },
  {
    path: "/tnc",
    component: () => import("./views/sessions/tnc"),
  },

  {
    path: "/register",
    component: () => import("./views/sessions/signUp"),
  },

  {
    path: "*",
    component: () => import("./views/pages/notFound"),
  },
];

const router = new Router({
  mode: "history",
  linkActiveClass: "open",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
// var liveChat = document.getElementById('chat-widget-container');
router.beforeEach(async (to, from, next) => {
  await getToken();
  if (from.query.id && !from.path.includes('sessions')) {
    if (!to.query.id && !to.path.includes('sessions')) {
      to.query.id = from.query.id;
      next({ path: to.path, query: to.query });
    } else {
      next();
    }
  } else {
    if (!from.path.includes('sessions') && !to.path.includes('sessions') && from.path != '/') {
      localStorage.removeItem('currencyToken');
      localStorage.removeItem('userID');

    }
    next();
  }

});

router.afterEach(() => {
  var content = document.getElementById("top");
  if (content) {
    content.scrollTo({ top: 0, behavior: "smooth" });
  }
  // Remove initial loading
  const gullPreLoading = document.getElementById("loading_wrap");
  if (gullPreLoading) {
    gullPreLoading.style.display = "none";
  }
  setTimeout(() => NProgress.done(), 100);
});

export default router;
