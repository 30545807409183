import { removeToken } from "./tokenHandling";


export default async (to, from, next) => {
  var token = localStorage.getItem('currencyToken') || '';
  var userInfo = localStorage.getItem("info") || '';


  try {
    userInfo = JSON.parse(userInfo);
  } catch (e) {
    userInfo = '';
  }

  if (token.length > 0) {
    if (userInfo.password2 == null) {
      next('/web/set-sec-password');
    }
    next();
  } else {
    removeToken();
    next('/web/sessions/login');
  }

};
